<template>
    <div @click="loginWithFacebook" class="btn-fb">
        <div class="fb-content d-flex">
            <div class="logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" version="1">
                    <path
                        fill="#FFFFFF"
                        d="M32 30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v28z"
                    />
                    <path
                        fill="#4267b2"
                        d="M22 32V20h4l1-5h-5v-2c0-2 1.002-3 3-3h2V5h-4c-3.675 0-6 2.881-6 7v3h-4v5h4v12h5z"
                    />
                </svg>
            </div>
            <p>Sign in with Facebook</p>
        </div>
    </div>
</template>
<script>
import LoginMixin from "@Platon/components/login/LoginMixin"

export default {
    name: "FacebookProvider",
    mixins: [LoginMixin],

    created() {
        if (this.$projectInfo.oauth2 && this.$projectInfo.oauth2.facebook_client_id) {
            window.fbAsyncInit = () => {
                FB.init({
                    appId: this.$projectInfo.oauth2.facebook_client_id,
                    cookie: true,
                    xfbml: true,
                    version: "v15.0",
                    oauth: true
                })

                FB.AppEvents.logPageView()
            }
            ;(function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) {
                    return
                }
                js = d.createElement(s)
                js.id = id
                js.src = "https://connect.facebook.net/en_US/sdk.js"
                fjs.parentNode.insertBefore(js, fjs)
            })(document, "script", "facebook-jssdk")
        } else {
            console.error("FACEBOOK Client ID not set!")
        }
    }
}
</script>
<style scoped lang="scss">
.btn-fb {
    margin: 0;
    padding: 0;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;

    .fb-content {
        height: 40px;
        display: flex;
        cursor: pointer;
        align-items: center;

        .logo {
            padding-left: 15px;
        }

        svg {
            width: 24px;
            height: 24px;
        }

        p {
            margin: 0;
            width: 100%;
            line-height: 1;
            letter-spacing: 0.21px;
            text-align: center;
            font-weight: 500;
            font-family: "Roboto", sans-serif;
        }
    }
}

.btn-fb {
    width: 100%;
    padding-top: 1.5px;
    background: #4267b2;

    &:hover {
    }

    &:active {
    }

    .fb-content p {
        color: rgba(white, 0.87);
    }
}
</style>
